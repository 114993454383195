














import { Component, Vue } from "vue-property-decorator";

@Component
export default class Login extends Vue {
  username = "";
  password = "";
  emailRules = [
    (v: string) => !!v || "E-mail is required",
    (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid",
  ];
  passwordRules = [
    (v: string) => !!v || "Password is required",
    (v: string) =>
      (v && v.length <= 16) || "Name must be less than 16 characters",
  ];
  window = window;
  $refs!: {
    form: HTMLFormElement;
  };
  valid = true;
  login(): void {
    this.$refs.form.validate();
    window.console.log(this.username, this.password);
    this.$store.dispatch("login", {
      username: this.username,
      password: this.password,
    });
  }
}
